
import AppMobileFullScreenContainer from "@/components/common/MobileFullScreenContainer.vue";
import AppToolbar from "@/components/common/ToolbarBase.vue";
import { Product, ProductExtraFields, ProductStock } from "@/models/base";
import { ProductService } from "@/services/product-service";
import Vue from "vue";
import AppTab2 from "./ProductEditBuy.vue";
import AppTab1 from "./ProductEditDefault.vue";
import AppTab4 from "./ProductEditExtraFields.vue";
import AppTab3 from "./ProductEditStock.vue";
import AppTab5 from "./ProductEditTaxes.vue";
import AppTab6 from "./ProductEditAwsDocuments.vue";
import AppTab7 from "./ProductEditPriceRules.vue";
import { UserConfigService } from "../../services/user-config-service";
import { ConfirmResult, ISelectItem } from "winfakt-vue-components";
import { DossierConfigService } from "@/services";
import {ShopService} from "@/services/shop-service";
import { getModel } from "@/utils/models/model";

export default Vue.extend({
	data() {
		return {
			tab: 0,
			pendingChanges: false,
			lastProductState: "{}",
			autoUpdateProductComposition: false,
			generalSettings: UserConfigService.getGeneralSettings(),
			autoBarcode: "",
			isSaving: false
		};
	},
	props: {
		value: {
			type: Object as () => Product,
			required: true,
		},
		showEditComposition: Boolean,
		drawerButtonDisabled: Boolean,
	},
	computed: {
		buttonBarItems():ISelectItem<string>[]{
			let result:ISelectItem<string>[] =  [
				{
					text: this.$ct("edit-composition"),
					value: "editComposition"},
				{
					text: this.$ct("load-last-opened"),
					value: "loadLastOpenedItem",
					disabled: this.lastLoadedDisabled
				}
			];
			return result;
		},
		currentTab(): string {
			return this.tabs[this.tab];
		},
		showPurchaseDetails():boolean{
			return UserConfigService.hasCurrentUserPermission(p=>p.CanReadProductPurchaseDetails);
		},
		tabs(): string[] {
			if (this.showPurchaseDetails){
				return [
					this.$ct("tabs.general"),
					this.$ct("tabs.buy"),
					this.$ct("tabs.stock"),
					this.$ct("tabs.extra-fields"),
					this.$ct("tabs.taxes"),
					this.$ct("tabs.documents"),
					this.$ct("tabs.price-rules")
				].map(t=>t.toString());
			}
			return [
				this.$ct("tabs.general"),
				this.$ct("tabs.stock"),
				this.$ct("tabs.extra-fields"),
				this.$ct("tabs.taxes"),
				this.$ct("tabs.documents"),
				this.$ct("tabs.price-rules")
			].map(t=>t.toString());
		},
		visibleTabIndex():number{
			if (this.showPurchaseDetails){
				return this.tab;
			}
			if (!this.showPurchaseDetails && this.tab != 0) {
				return this.tab+1;
			}
			return this.tab;
		},
		product(): Product {
			return this.value;
		},
		titleKey():string {
			return this.value.ID == 0 ? "title-new" : "title-edit";
		},
		lastLoadedDisabled():boolean{
			if (this.value.ID != 0){
				return true;
			}
			return !ProductService.lastOpenedItem.hasStoredItem();
		},
	},
	watch: {
		product: {
			immediate: true,
			deep: true,
			handler(product: Product, oldProduct: Product) {
				if (oldProduct != product) {
					this.lastProductState = JSON.stringify(product.getJSON());
					return;
				}
				if (JSON.stringify(product.getJSON()) == this.lastProductState) return;
				this.pendingChanges = true;
				if (this.product.ID == 0 && this.product.Stock == null){
					this.product.Stock = new ProductStock();
				}
			}
		},
	},
	methods: {
		setCalculatedFields(){
			if (!this.product.ExtraFields){
				this.product.ExtraFields = new ProductExtraFields();
			}
			let model = getModel("Product");
			let fields = model.getCalculatedFields();
			for (let field of fields) {
				field.storeExtraFieldValue(this.product, this.product.ExtraFields);
			}
		},
		calculateEan13CheckDigit(ean13Code:string) {
			const weights = [1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1];
			let sum = 0;
			for (let i = 0; i < 12; i++) {
				sum += parseInt(ean13Code[i]) * weights[i];
			}
			const checkDigit = (10 - (sum % 10)) % 10;
			return checkDigit;
		},
		createEan13Barcode(barcode:number):string {
			let barcodeString = `${barcode}`;
			if (barcodeString.length >= 13) return barcodeString;
			if (12 - barcodeString.length <= 0) return `${barcodeString}${this.calculateEan13CheckDigit(barcodeString)}`;
			barcodeString = `${"0".repeat(12 - barcodeString.length)}${barcode}`;
			return `${barcodeString}${this.calculateEan13CheckDigit(barcodeString)}`;
		},
		setAutoBarcode(){
			if (this.product.ID != 0) return;
			let autoBarcodeConfig = DossierConfigService.getAutoBarcodeConfig();
			if (!autoBarcodeConfig.Enabled) return;
			let barcode = autoBarcodeConfig.NextBarcode;
			let barcodeString = this.createEan13Barcode(barcode);
			this.autoBarcode = barcodeString;
			this.product.BarCode = barcodeString;
		},
		incremenetAutoBarcode(){
			let autoBarcodeConfig = DossierConfigService.getAutoBarcodeConfig();
			if (!autoBarcodeConfig.Enabled)return;
			let autoBarcode = this.createEan13Barcode(autoBarcodeConfig.NextBarcode);
			if (this.autoBarcode != autoBarcode) return;
			if (this.product.BarCode != this.autoBarcode) return;
			ProductService.incrementAutoBarcode();
		},
		async askToSave(): Promise<boolean> {
			let cB = this.$confirmBuilder();
			cB.setTitle(this.$ct("common.attention"));
			cB.setClass("warning");
			cB.setMessage(this.$ct("save-required"));
			return (await cB.show()) == ConfirmResult.YES;
		},
		// eslint-disable-next-line max-lines-per-function
		async save(returnToView:boolean = false):Promise<boolean> {
			if (this.isSaving) return false;
			this.isSaving = true;
			try{

				if (!this.product.Sku && !this.generalSettings.AutoAssignProductSku) {
					this.$wf.notify(this.$ct("error-sku-empty").toString(), "danger", 3500, "exclamation-triangle");
					return false;
				}

				this.setCalculatedFields();
				if (!await this.beforeSave()) {
					return false;
				}

				if (this.product.ID != 0 && this.product.ID != null) {
					var product = await ProductService.putProduct(this.product);
				} else {
					var product = await ProductService.postProduct(this.product);
					await this.incremenetAutoBarcode();
				}

				if(DossierConfigService.getGeneralConfig().AutoShopSynch){
					await ShopService.update(product.ID);
				}

				if(this.generalSettings.AutoUpdateProductComposition){
					await ProductService.updateProductCompositions(product.ID);
				}

				this.$emit("input", product);
				this.pendingChanges = false;
				this.$wf.notify(this.$ct("save-success").toString(), "success", 3000, "check");
				this.$emit("clearChanges");
				this.$emit("saved", product, returnToView);

				return true;
			}catch(err){

			}finally{
				this.isSaving = false;
			}
			return false;
		},
		async beforeSave() {
			if (!this.product.ID && !this.generalSettings.AutoAssignProductSku) {
				if(!await this.checkIfSkuIsFree()){
					return false;
				}
			}
			return true;
		},
		async checkIfSkuIsFree(){
			let exists = await ProductService.existsBySkuIncludeNonActive(this.product.Sku + "");
			if (exists) {
				this.$wf.notify(this.$ct("error-sku-exists").toString(), "danger", 3500, "exclamation-triangle");
				return false;
			}
			return true;
		},
		async editComposition() {
			if (this.pendingChanges || this.product.ID == 0) {
				let result = await this.askToSave();
				if (!result) {
					return;
				}
				if (!await this.save()) {
					return;
				}
			}
			this.$emit("clearChanges");
			this.$router.push(`/products/composition/${this.product.ID}`);
		},
		trigger(value: string) {
			(this as any)[value]();
		},
		async cancel() {
			this.$emit("cancel");
		},
		async remove() {
			let confirm = await this.$wf.confirm(this.$ct("confirm-delete"), this.$ct("common.yes"), this.$ct("common.no"), this.$ct("common.warning"), "warning");
			if (!confirm) return;
			await ProductService.delProducts([this.value]);
			this.$emit("delete");
		},
		loadLastOpenedItem(){
			let product = new Product(ProductService.lastOpenedItem.getLastOpenedItem());

			product.ID = 0;
			product.Sku = null;
			this.$emit("input", product);
		}
	},
	created(){
		this.$watch("product", this.setAutoBarcode, {immediate: true, deep: false});
	},
	components: {
		AppTab1,
		AppTab2,
		AppTab3,
		AppTab4,
		AppTab5,
		AppTab6,
		AppTab7,
		AppToolbar,
		AppMobileFullScreenContainer,
	},
	i18nEx: {
		componentPrefix: "components.products"
	}
});
