import {v4 as uuid} from "uuid";
import { getFunctionByOperator } from "./operator-functions";
import { i18n } from "@/setup/i18n-setup";
import { IConditionableField } from "./conditionable-field";

export enum Operator {
	EQ = 0,
	NEQ = 1,
	GT = 2,
	LT = 3,
	GT_OR_EQ = 4,
	LT_OR_EQ = 5,
	IS_EMPTY = 6,
	IS_NOT_EMPTY = 7,

	// Date specific
	WITHIN_DAYS_AGO = 8,
	WITHIN_MONTHS_AGO = 9,
	WITHIN_YEARS_AGO = 10,
	OVER_DAYS_AGO = 11,
	OVER_MONTHS_AGO = 12,
	OVER_YEARS_AGO = 13,
	WITHIN_DAYS = 14,
	WITHIN_MONTHS = 15,
	WITHIN_YEARS = 16,
	OVER_DAYS = 17,
	OVER_MONTHS = 18,
	OVER_YEARS = 19,

	// String specific
	StartsWith = 20,
	EndsWith = 21,
	Contains = 22,
	DoesNotStartWith = 23,
	DoesNotEndWith = 24,
	DoesNotContain = 25,

	// Time specific
	WITHIN_HOURS_AGO = 26,
	WITHIN_MINUTES_AGO = 27,
	OVER_HOURS_AGO = 28,
	OVER_MINUTES_AGO = 29,
	WITHIN_HOURS = 30,
	WITHIN_MINUTES = 31,
	OVER_HOURS = 32,
	OVER_MINUTES = 33
}

export enum ConditionPropertyType {
	EMPTY = 0,
	NUMBER = 1,
	BOOL = 2,
	STRING = 3,
	DATE = 4,
	SALE_TYPE = 5,
	BANK_KIND = 6,
}

export interface IConditionProp{
	prop:string;
	mapField:string;
	type:ConditionPropertyType;
	translatedName:string;
}

export class Condition{
	// Key is used by the layout and should not be sent to the server
	key:string = uuid();

	IsOr:boolean = false;
	Prop:string  = "";
	Operator:Operator = Operator.EQ;
	Value:string = "";
	UseFieldValue:boolean = false;

	public constructor(data?:any){
		if (!data) return;
		this.IsOr = data.IsOr;
		this.Prop = data.Prop;
		this.Operator = data.Operator;
		this.Value = data.Value;
		this.UseFieldValue = data.UseFieldValue || false;
	}

	public getJSON():any{
		return {
			IsOr: this.IsOr,
			Prop: this.Prop,
			Operator: this.Operator,
			Value: this.Value,
			UseFieldValue: this.UseFieldValue
		};
	}

	public static convertModelFieldToConditionPropertyType(field:IConditionableField):ConditionPropertyType{
		if (field.filterValueInput){
			if (field.filterValueInput == "SaleType") {
				return ConditionPropertyType.SALE_TYPE;
			}
			if (field.filterValueInput == "BankKind") {
				return ConditionPropertyType.BANK_KIND;
			}
		}
		let t = field.type;
		if (t == "boolean") {
			return ConditionPropertyType.BOOL;
		}
		if (t == "string") {
			return ConditionPropertyType.STRING;
		}
		if (t == "number") {
			return ConditionPropertyType.NUMBER;
		}
		if (t == "date") {
			return ConditionPropertyType.DATE;
		}
		return ConditionPropertyType.EMPTY;
	}

	public static getAvailableOperatorsOfType(t:ConditionPropertyType):Operator[]{
		if (t == ConditionPropertyType.BOOL) {
			return [Operator.EQ, Operator.NEQ];
		}
		if (t == ConditionPropertyType.STRING) {
			return [
				Operator.EQ, Operator.NEQ, Operator.IS_EMPTY, Operator.IS_NOT_EMPTY,
				Operator.StartsWith, Operator.EndsWith, Operator.Contains,
				Operator.DoesNotStartWith, Operator.DoesNotEndWith, Operator.DoesNotContain
			];
		}
		if (t == ConditionPropertyType.EMPTY) {
			return [Operator.EQ, Operator.NEQ, Operator.IS_EMPTY, Operator.IS_NOT_EMPTY];
		}
		if (t == ConditionPropertyType.DATE) {
			return [
				Operator.EQ,Operator.NEQ,Operator.GT,Operator.LT,Operator.GT_OR_EQ,Operator.LT_OR_EQ,Operator.IS_EMPTY,Operator.IS_NOT_EMPTY,
				Operator.WITHIN_DAYS_AGO, Operator.WITHIN_MONTHS_AGO, Operator.WITHIN_YEARS_AGO,
				Operator.OVER_DAYS_AGO, Operator.OVER_MONTHS_AGO, Operator.OVER_YEARS_AGO,
				Operator.WITHIN_DAYS, Operator.WITHIN_MONTHS, Operator.WITHIN_YEARS,
				Operator.OVER_DAYS, Operator.OVER_MONTHS, Operator.OVER_YEARS,
				Operator.WITHIN_HOURS_AGO, Operator.WITHIN_MINUTES_AGO,
				Operator.OVER_HOURS_AGO, Operator.OVER_MINUTES_AGO,
				Operator.WITHIN_HOURS, Operator.WITHIN_MINUTES,
				Operator.OVER_HOURS, Operator.OVER_MINUTES,
			];
		}
		if (t == ConditionPropertyType.NUMBER) {
			return [ Operator.EQ,Operator.NEQ,Operator.GT,Operator.LT,Operator.GT_OR_EQ,Operator.LT_OR_EQ,Operator.IS_EMPTY,Operator.IS_NOT_EMPTY];
		}
		if (t == ConditionPropertyType.SALE_TYPE || t == ConditionPropertyType.BANK_KIND) {
			return [Operator.EQ, Operator.NEQ];
		}
		return [Operator.IS_EMPTY, Operator.IS_NOT_EMPTY];
	}

	public test(value:any):boolean {
		if (!value) return false;
		let currentVal = this.getValueByPath(value, this.Prop);
		let compareVal = this.Value;
		if (this.UseFieldValue){
			compareVal = this.getValueByPath(value, this.Value);
		}
		return getFunctionByOperator(this.Operator)(currentVal, compareVal);
	}

	private getValueByPath(value:any, path:string):any{
		let parts = path.split(".");
		let currentVal = value;
		for (let part of parts){
			if (!currentVal){
				return false;
			}
			currentVal = currentVal[part];
		}
		return currentVal;
	}

	public static checkConditions(conditions:Condition[], value:any):boolean {
		let result = true;
		for (let i in conditions) {
			let condition = conditions[i];
			let currentValue = condition.test(value);
			if (i == "0") {
				result = currentValue;
				continue;
			}
			if (condition.IsOr) {
				result = result || currentValue;
			}else{
				result = result && currentValue;
			}
		}
		return result;
	}

	public static operatorToShortString(operator:Operator):string {
		return i18n.t(`common.condition.short-operators.${operator}`).toString();
	}

	public valueToString(getFieldName:(val:string)=>string){
		if (this.UseFieldValue){
			console.log("Getting field name of ", this.Value);
			return getFieldName(this.Value);
		}
		return this.Value;
	}


	public conditionToShortString(getFieldName:(val:string)=>string):string{
		if (this.Operator == Operator.IS_EMPTY || this.Operator == Operator.IS_NOT_EMPTY){
			return `${getFieldName(this.Prop)} ${Condition.operatorToShortString(this.Operator)}`;
		}
		return `${getFieldName(this.Prop)} ${Condition.operatorToShortString(this.Operator)} ${this.valueToString(getFieldName)}`;
	}

	public static conditionsToShortString(getFieldName:(val:string)=>string, conditions:Condition[]):string {
		let res = "";
		if (conditions.length == 0){
			return i18n.t("common.empty").toString();
		}
		for (let i = 0; i < conditions.length;i++){
			let condition = conditions[i];
			if (i > 0){
				if (condition.IsOr){
					res += ` ${i18n.t("common.or")} `;
				}else{
					res += ` ${i18n.t("common.and")} `;
				}
			}
			res += condition.conditionToShortString(getFieldName);
		}
		return res;
	}
}