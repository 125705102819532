import { UserConfigService } from "@/services/user-config-service";
import { getPreloadsFromFilters, HumanFilter } from "@/utils/human-filter";
import { browseFieldPathToFilterFieldPath } from "@/utils/models/utils";
import { IAdvancedQueryParams } from "@/utils/query-params";
import { View } from "@/models/view";
import { Axios } from "../axios";
import { AxiosResponse } from "axios";
import { getModel } from "../models/model";

function processFilters(filters:HumanFilter[], query:IAdvancedQueryParams):any[]{
	if (filters.length > 0) {
		filters[0].IsOr = false;
	}
	let model = getModel(query.view.Table);
	return filters.map(f=>f.getProcessedJson(model));
}

export async function advancedSearch(query: IAdvancedQueryParams, url: string, additionalFilters:HumanFilter[] = [], additionalHeaders?:any):Promise<AxiosResponse> {
	let extraFilters = [] as HumanFilter[];
	if (query.searchQuery != "") {
		if (query.searchByAllColumns) {
			extraFilters = HumanFilter.getQuickFilters(query.view.Columns.filter(f => !f.IsComputed).map((c) => browseFieldPathToFilterFieldPath(c.Property)),
				query.searchQuery || "");
		} else {
			let settings = UserConfigService.getBrowseSearchSettings();
			let fields = settings.getSettings(query.view.BrowseID);
			extraFilters = HumanFilter.getQuickFilters(fields, query.searchQuery || "");
		}
	}

	let filters = [...extraFilters, ...(query.filters || [])];
	filters.push(...additionalFilters);
	let preloads = getPreloadsFromFilters(filters);

	let params = {
		limit: query.limit,
		order: browseFieldPathToFilterFieldPath(query.orderBy),
		orderDirection: query.orderDirection,
		filters: processFilters(filters, query),
		preloads,
		offset: query.offset,
		searchQuery: query.searchQuery,
		autoWildCardSearch: query.autoWildCardSearch,
		searchByAllColumns: query.searchByAllColumns,
		dontSaveSettings: additionalFilters.length > 0 || query.dontSaveViewSettings ? "true" : "false"
	} as any;

	let result = await Axios.get(`${url}/${query.view.ID}`, {params, headers: additionalHeaders || {}});
	return result;
}

export async function advancedSearchStaticView(view:View, query: IAdvancedQueryParams, url: string, additionalFilters:HumanFilter[] = [], additionalHeaders?:any):Promise<AxiosResponse> {
	let extraFilters = [] as HumanFilter[];
	if (query.searchQuery != "") {
		if (query.searchByAllColumns) {
			extraFilters = HumanFilter.getQuickFilters(query.view.Columns.filter(f => !f.IsComputed).map((c) => browseFieldPathToFilterFieldPath(c.Property)),
				query.searchQuery || "");
		} else {
			let settings = UserConfigService.getBrowseSearchSettings();
			let fields = settings.getSettings(query.view.BrowseID);
			extraFilters = HumanFilter.getQuickFilters(fields, query.searchQuery || "");
		}
	}

	view.computePreloads();
	let filters = [...extraFilters, ...(query.filters || [])];
	filters.push(...additionalFilters);
	let preloads = getPreloadsFromFilters(filters);

	let params = {
		limit: query.limit,
		order: browseFieldPathToFilterFieldPath(query.orderBy),
		orderDirection: query.orderDirection,
		filters: processFilters(filters, query),
		preloads,
		page: query.offset,
		searchQuery: query.searchQuery,
		autoWildCardSearch: query.autoWildCardSearch,
		dontSaveSettings: additionalFilters.length > 0 || query.dontSaveViewSettings ? "true" : "false",
		view: view.getJSON()
	} as any;

	let result = await Axios.get(`${url}`, {params, headers: additionalHeaders || {}});
	return result;
}
