import { i18n } from "@/setup/i18n-setup";
import { printLocalDateTime } from "@/utils/pretty-print";
import { computed, field } from "@/utils/models/decorator-field";
import { model } from "@/utils/models/decorator-model";
import { DossierService } from "@/services/dossier-service";
import CrmHistoryCall from "@/models/crm/crm-history-call";
import CrmHistoryAppointment from "@/models/crm/crm-history-appointment";
import CrmHistoryMessage from "@/models/crm/crm-history-message";
import CrmHistoryEmail from "@/models/crm/crm-history-email";
import CrmHistoryClose from "@/models/crm/crm-history-close";
import CrmHistorySale from "@/models/crm/crm-history-sale";
import CrmHistoryOffer from "@/models/crm/crm-history-offer";

export enum CrmHistoryEventType {
	Message = 1,
	Call = 2,
	Email = 3,
	Appointment = 4,
	Close = 5,
	Sale = 6,
	Offer = 7,
}

@model("CrmHistory")
export default class CrmHistory {
	@field("number")
	public ID: number = 0;

	@field("number")
	public CrmID: Number = 0;

	@field("date")
	public TimeStamp: Date = new Date();

	@field("number")
	public EventTypeID: CrmHistoryEventType = 1;

	@field("array")
	public Props: {} = {}

	@field("array")
	public ComputedProps: string[] = [];

	@field("number")
	public CreatorID: number = 0;

	@field("string")
	@computed()
	public get Creator(): string {
		let user = DossierService.getUser(this.CreatorID);
		return user.getUserFullName();
	}


	public constructor(data?: any) {
		if (!data) {
			return;
		}
		this.ID = data.ID;
		this.CrmID = data.CrmID;
		this.CreatorID = data.CreatorID;
		this.TimeStamp = new Date(data.TimeStamp);
		this.EventTypeID = data.EventTypeID;
		this.Props = data.Props;
		this.ComputedProps = this.getEventProps();
	}

	public getJSON() {
		return {
			ID: this.ID,
			CrmID: this.CrmID,
			CreatorID: this.CreatorID,
			TimeStamp: this.TimeStamp.toJSON(),
			EventTypeID: this.EventTypeID,
			Props: this.Props,
		};
	}

	public getTimeStampString(): string {
		return printLocalDateTime(this.TimeStamp);
	}

	public getEventString(): string {
		if (this.EventTypeID == CrmHistoryEventType.Message) {
			return i18n.t("models.CrmHistoryEventType.message").toString();
		}
		if (this.EventTypeID == CrmHistoryEventType.Call) {
			return i18n.t("models.CrmHistoryEventType.call").toString();
		}
		if (this.EventTypeID == CrmHistoryEventType.Email) {
			return i18n.t("models.CrmHistoryEventType.email").toString();
		}
		if (this.EventTypeID == CrmHistoryEventType.Appointment) {
			return i18n.t("models.CrmHistoryEventType.appointment").toString();
		}
		if (this.EventTypeID == CrmHistoryEventType.Close) {
			return i18n.t("models.CrmHistoryEventType.close").toString();
		}
		if (this.EventTypeID == CrmHistoryEventType.Sale) {
			return i18n.t("models.CrmHistoryEventType.sale").toString();
		}
		if (this.EventTypeID == CrmHistoryEventType.Offer) {
			return i18n.t("models.CrmHistoryEventType.offer").toString();
		}

		throw new Error("Invalid event type");
	}

	// eslint-disable-next-line max-lines-per-function
	public getEventProps(): any {
		if (this.EventTypeID == CrmHistoryEventType.Message) {
			let p = new CrmHistoryMessage(this.Props);
			if (this.Props)
				return {
					title: i18n.t("components.crm.event-titles."+CrmHistoryEventType.Message).toString(),
					message: p.Message,
					icon: "info"
				};
		}
		if (this.EventTypeID == CrmHistoryEventType.Call) {
			let p = new CrmHistoryCall(this.Props);
			if (this.Props)
				return {
					title: i18n.t("components.crm.event-titles."+CrmHistoryEventType.Call).toString()
							+ (p.Contact ? " "+i18n.t("components.crm.with").toString()+" " + p.Contact : "" )
							+ (p.Phone ? " "+i18n.t("components.crm.nr-called").toString()+" " + p.Phone : "" ),
					message: p.Message,
					icon: "phone"
				};
		}
		if (this.EventTypeID == CrmHistoryEventType.Email) {
			let p = new CrmHistoryEmail(this.Props);
			if (this.Props)
				return {
					title: i18n.t("components.crm.event-titles."+CrmHistoryEventType.Email).toString(),
					message: p.Message,
					icon: "envelope"
				};
		}
		if (this.EventTypeID == CrmHistoryEventType.Appointment) {
			let p = new CrmHistoryAppointment(this.Props);
			if (this.Props)
				return {
					title: i18n.t("components.crm.event-titles."+CrmHistoryEventType.Appointment).toString(),
					message: p.Message,
					icon: "calendar-check"
				};
		}
		if (this.EventTypeID == CrmHistoryEventType.Close) {
			let p = new CrmHistoryClose(this.Props);
			if (this.Props)
				return {
					title: i18n.t("components.crm.event-titles."+CrmHistoryEventType.Close).toString(),
					message: p.Message,
					icon: "power-off"
				};
		}
		if (this.EventTypeID == CrmHistoryEventType.Sale) {
			let p = new CrmHistorySale(this.Props);
			if (this.Props)
				return {
					title: i18n.t("components.crm.event-titles."+CrmHistoryEventType.Sale).toString(),
					message: p.Message,
					saleId: p.SaleID,
					icon: "money-check-alt"
				};
		}
		if (this.EventTypeID == CrmHistoryEventType.Offer) {
			let p = new CrmHistoryOffer(this.Props);
			if (this.Props)
				return {
					title: i18n.t("components.crm.event-titles."+CrmHistoryEventType.Offer).toString(),
					message: p.Message,
					saleId: p.SaleID,
					icon: "shopping-bag"
				};
		}
	}
}
