
import {ContactPriceRule} from "@/models/base/contact-price-rule";
import AppProductSkuAutocomplete from "@/components/products/ProductSkuAutocompleteWithoutModal.vue";
import AppContactSearch from "@/components/contacts/ContactSearchWithBrowse.vue";
import AppProductEditGroups from "@/components/products/ProductEditGroupsLight.vue";
import AppFilterItem from "./ContactEditPriceRuleFilterItem.vue";
import Vue from "vue";
import {Condition, Operator} from "@/utils/condition/condition";
import AppSellCategorySelect from "@/components/SellCategorySelect.vue";
import {Contact, Product} from "@/models/base";
import {Browse} from "@/models/browse";
import {TranslateResult} from "vue-i18n";
import {ISelectItem} from "winfakt-vue-components";
import {ProductGroupModel} from "@/models/base/product-group-model";

export default Vue.extend({
	data() {
		return {
			rule: new ContactPriceRule(),
			product: new Product(),
			addContact: new Contact(),
			openedCondition: null as null | Condition,
			activeTab: 0,
			activeQuickFilter: 0,
			amount: 0
		};
	},
	props: {
		value: {
			type: Object as () => ContactPriceRule,
			required: true
		},
		contact: {
			type: Object as () => Contact,
			required: true
		},
		browse: {
			type: Object as () => Browse
		}
	},
	computed: {
		disableSellPrice(): boolean {
			return this.rule.BuyPercentage != 0 || this.rule.BuyAmount != 0;
		},
		disableBuyPrice() {
			return this.rule.Price != 0 || this.rule.Discount != 0;
		},
		tabs(): TranslateResult[] {
			return Object.values(this.$ct("price-rule-tabs"));
		},
		select(): ISelectItem<number>[] {
			return Object.values(this.$ct("price-rule-quick-filter-select")).map((k, v) => {
				return {value: v, text: k};
			});
		},
	},
	watch: {
		value: {
			immediate: true,
			handler(value: ContactPriceRule) {
				this.rule = new ContactPriceRule(this.value.getJSON());
				if (this.rule.PriceCategory == 0) {
					this.rule.PriceCategory = this.contact.SellPriceCategory;
				}
			}
		}
	},
	methods: {
		close() {
			this.$emit("close");
		},
		save() {
			this.$emit("input", new ContactPriceRule(this.rule.getJSON()));
			this.close();
		},
		addNewFilter() {
			let condition = new Condition();
			condition.Prop = "ProductSku",
			condition.Operator = Operator.EQ;
			condition.Value = "1";
			this.rule.Filters.push(condition);
			this.openedCondition = condition;
		},
		deleteItem(i: number) {
			this.rule.Filters.splice(i, 1);
		},
		editItem(item: Condition) {
			if (this.openedCondition == item) {
				this.openedCondition = null;
				return;
			}
			this.openedCondition = item;
		},
		onSingleProduct(item:Product) {
			let c = new Condition({"Prop": "ProductSku","Operator": 0,"Value": item.Sku});
			this.rule.Filters.push(c);
		},onProductSelect(items:Product[]) {
			let i = items.length > 1 ? true : false;
			items.forEach((item:Product) => {
				let c = new Condition({"IsOr": i,"Prop": "ProductSku","Operator": 0,"Value": item.Sku});
				this.rule.Filters.push(c);
			});
		},
		onSupplierSelect(item:Contact) {
			let c = new Condition({"Prop": "Product.MainSupplier.Contact.ComputedFriendlyID","Operator": 0,"Value": item.ComputedFriendlyID});
			this.rule.Filters.push(c);
			return false;
		},
		onQtySelect() {
			let c = new Condition({"Prop": "Amount","Operator": 4,"Value": this.amount.toString()});
			this.rule.Filters.push(c);
			this.amount = 0;
			return false;
		},
		onGroupSubmit(){
			if(this.product.MainGroup){
				let c = new Condition({"Prop": "Product.MainGroup.ParentGroupID","Operator": 0,"Value": this.product.MainGroup.ID.toString()});
				this.rule.Filters.push(c);
				this.amount = 0;
				return false;
			}
			if(this.product.GroupID){
				let c = new Condition({"Prop": "Product.GroupID","Operator": 0,"Value": this.product.GroupID.toString()});
				this.rule.Filters.push(c);
				this.amount = 0;
				return false;
			}
		},
		setMainGroup(id:number){
			this.product.MainGroup = new ProductGroupModel({ID: id});
			this.product.GroupID = id;
		}
	},
	components: {
		AppFilterItem,
		AppSellCategorySelect,
		AppProductSkuAutocomplete,
		AppProductEditGroups,
		AppContactSearch
	},
	i18nEx: {
		componentPrefix: "components.contacts"
	}
});
