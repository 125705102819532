import { render, staticRenderFns } from "./ContactEditPriceRule.vue?vue&type=template&id=69479c65&scoped=true"
import script from "./ContactEditPriceRule.vue?vue&type=script&lang=ts"
export * from "./ContactEditPriceRule.vue?vue&type=script&lang=ts"
import style0 from "./ContactEditPriceRule.vue?vue&type=style&index=0&id=69479c65&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69479c65",
  null
  
)

export default component.exports